





















































import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component, Prop } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import accessControl from "@/api/access_control";
import { AuthInfo } from "@/models/user";
import AuthsDetails from "@/views/usermanagement/employees/AuthsDetails.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

@Component({
  components: { AuthsDetails, Loader, ConfirmationModal },
})
@Translatable("usermanagement.employees")
export default class AuthsLoader extends mixins<TranslatableComponent>() {
  private loading: boolean = false;
  private resetClicked: boolean = false;
  private deleteClicked: boolean = false;
  private authData: AuthInfo | null = null;

  @Prop({ required: true, type: String })
  readonly userId!: string;

  mounted() {
    this.loadLogins();
  }

  private async loadLogins() {
    this.loading = true;
    this.authData = await accessControl.usermanagement.auths
      .index(this.userId)
      .then((response) => response.data)
      .catch(() => {
        this.$snotify.error(`${this.translated_view_value("auth_load_error")}`);
        return null;
      });
    this.loading = false;
  }

  private showDeleteCredentials() {
    if (this.deleteClicked) {
      return;
    }
    this.$modal.show(`delete-auth-${this.userId}`);
  }

  private deleteCredentials() {
    this.deleteClicked = true;
    accessControl.usermanagement.auths
      .delete(this.userId)
      .then(() => {
        this.$snotify.success(
          this.translated_view_value("delete_auth.success")
        );
      })
      .then(() => {
        this.authData = null;
      })
      .catch(() => {
        this.$snotify.error(this.translated_view_value("delete_auth.error"));
      })
      .finally(() => {
        this.deleteClicked = false;
      });
  }

  private onUserClicked() {
    if (this.resetClicked) {
      return;
    }
    this.$modal.show(`otp-reset-${this.userId}`);
  }

  private resetOtpForUser(user: string) {
    this.resetClicked = true;
    accessControl.usermanagement.auths
      .reset_otp(user)
      .then(() => {
        this.$snotify.success(this.translated_view_value("reset_otp.success"));
      })
      .then(() => {
        this.loadLogins();
      })
      .catch(() => {
        this.$snotify.error(this.translated_view_value("reset_otp.error"));
      })
      .finally(() => {
        this.resetClicked = false;
      });
  }
}
