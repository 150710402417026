









import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import FormInputField from "@/components/form-components/FormInputField.vue";
import BasicModal from "@/components/modals/partials/BasicModal.vue";

@Component({
  components: { BasicModal, FormInputField },
})
export default class ConfirmationModal extends Vue {
  @Prop() private componentName!: string;

  @Emit("cancel")
  private cancel() {}

  @Emit("submit")
  private submit() {}
}
